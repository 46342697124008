body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#video {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

}

#video-bg {
  position: relative;
  width: auto;
  min-width: 100%;
  height: auto;
  /* background: transparent url(../public/imgs/explosion2.mp4) no-repeat; */
  background-size: cover;
}

/* .app-bg {
  background: #36110A url(../public/imgs/lava3.jpeg) no-repeat;
  background-size: cover;
} */
video {
  display: block;
}
.video-container {
  width: 100%;
  height: 100%;
  background-color: black;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  z-index: -100;
}

#credit-link {
  text-decoration: none;
  color: #FECB2E;
  letter-spacing: 2px;
  font-weight: 600;
}


#helpLink {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 2px;
  font-size: 12px;
  font-style: italic;
}

#lobbyButton {
  border: none;
  background-color: none;
}
#username-form-disabled {
  color:aqua;
}

#new_message {
  &:focus {
    /* outline: none; */
    outline-color: #2ba5f7;
    outline-style: solid;
    /* box-shadow: 0 0 10px #719ECE; */
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
